import { Pub } from '../../types';
import { baseUrl } from '../base-url';

export const fetchPub = async (googlePlaceId: string) => {
  try {
    const token = localStorage.getItem('token');
    const response = await fetch(`${baseUrl}/api/pub/${googlePlaceId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status === 200) {
      const pub: Pub | null = await response.json();

      return pub;
    } else {
      return null;
    }
  } catch (err) {
    console.log('Failed to fetch pub.', err);
    throw err;
  }
};
