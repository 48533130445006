// Cloudflare
export const turnstileSiteKey = '0x4AAAAAAAygGHIWrJQLpq2a';

// Key has been restricted to only my websites
export const googlePlacesApiProdKey = 'AIzaSyDunC_NWo3QVp0tKYoebbGu4822ezaDUWE';
export const googlePlacesApiDevKey = 'AIzaSyARkkWrFXNrVXKlexQ0zJsiO7OyLugXK4c';

// Shared
export const getGooglePlacesKey = () => {
  return process.env.NODE_ENV === 'development'
    ? googlePlacesApiDevKey
    : googlePlacesApiProdKey;
};
