import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CircleIcon from '@mui/icons-material/Circle';
import StarIcon from '@mui/icons-material/Star';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  colors,
  desktopBreakpoint,
  spacing,
} from '../../../../app/components/styled';
import { SectionWrapper } from '../../../shared/components/section-wrapper';
import { StyledParagraph } from './styled';

interface Review {
  heading: string;
  text: string;
  name: string;
}

const reviews: Review[] = [
  {
    heading: 'So Helpful',
    text: 'I used the app to find a spot to meet friends after work. It was really helpful to check the menu in advance so everyone could plan their budget for the while waiting for payday.',
    name: 'Erin Moriarty',
  },
  {
    heading: 'Simple Way to Find a Place',
    text: 'Had no idea there were so many options in my area to go grab a bite and a pint on the cheap!',
    name: 'Obrowne',
  },
  {
    heading: 'Super Useful App',
    text: 'Spent a weekend in Vancouver with my partner where we found the app really useful and it allowed o make the most of our short time there! Would really recommend!',
    name: 'Niamh Carmody',
  },
  {
    heading: 'Brilliant App!',
    text: `I used it on a trip to Vancouver lately and it helped me find some great bars that I wouldn't have visited otherwise. Not to mention the savings from happy hours! Couldn't recommend it enough.`,
    name: 'Paddy M',
  },
];

export const ReviewsSection = () => {
  const [index, setIndex] = useState(0);
  const [autoplay, setAutoplay] = useState(true);
  const { heading, text, name } = reviews[index];
  let interval: NodeJS.Timeout;

  useEffect(() => {
    if (!autoplay) {
      clearInterval(interval);
    } else {
      interval = setInterval(() => {
        setIndex((prevState) =>
          index === reviews.length - 1 ? 0 : prevState + 1
        );
      }, 4000);
    }

    return () => clearInterval(interval);
  }, [index, autoplay]);

  const handleNextClick = () => {
    setAutoplay(false);

    if (index === reviews.length - 1) {
      setIndex(0);
    } else {
      setIndex(index + 1);
    }
  };

  const handlePreviousClick = () => {
    setAutoplay(false);

    if (index === 0) {
      setIndex(reviews.length - 1);
    } else {
      setIndex(index - 1);
    }
  };

  return (
    <SectionWrapper backgroundColor={colors.slate}>
      <GridContainer>
        <HeadingWrapper>
          <h1>What our users are saying</h1>
        </HeadingWrapper>
        <ReviewContainer>
          <h3>{heading}</h3>
          <StarsWrapper>
            <StarIcon htmlColor='gold' />
            <StarIcon htmlColor='gold' />
            <StarIcon htmlColor='gold' />
            <StarIcon htmlColor='gold' />
            <StarIcon htmlColor='gold' />
          </StarsWrapper>
          <ReviewWrapper>
            <StyledParagraph>{text}</StyledParagraph>
          </ReviewWrapper>
          <StyledParagraph
            style={{ fontStyle: 'italic', marginTop: spacing.sm }}
          >
            {name}
          </StyledParagraph>
          <ButtonsWrapper>
            <StyledButton onClick={handlePreviousClick} color={colors.slate}>
              <ChevronLeftIcon
                htmlColor={colors.slate}
                sx={{ fill: colors.slate, color: colors.slate }}
              />
            </StyledButton>
            <DotsWrapper>
              {reviews.map((review, circleIndex) => {
                return (
                  <StyledButton
                    key={review.name}
                    onClick={() => {
                      setAutoplay(false);
                      setIndex(circleIndex);
                    }}
                  >
                    <CircleIcon
                      fontSize='small'
                      htmlColor={colors.slate}
                      sx={{
                        height: '10px',
                        opacity: circleIndex !== index ? 0.15 : 1,
                      }}
                    />
                  </StyledButton>
                );
              })}
            </DotsWrapper>
            <StyledButton onClick={handleNextClick} color={colors.slate}>
              <ChevronRightIcon />
            </StyledButton>
          </ButtonsWrapper>
        </ReviewContainer>
      </GridContainer>
    </SectionWrapper>
  );
};

const GridContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
  margin-top: ${spacing.lg};
  margin-bottom: ${spacing.xl};
`;

const HeadingWrapper = styled.div`
  margin-bottom: ${spacing.lg};
  text-align: center;
  color: white;
`;

const ReviewContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  padding: ${spacing.lg};
  padding-top: ${spacing.md};
  padding-bottom: ${spacing.lg};
  display: flex;
  flex-direction: column;
  background-color: ${colors.paleGrey};
  max-width: 600px;
  border-radius: 10px;
  color: ${colors.slate};
  box-sizing: border-box;

  @media ${desktopBreakpoint} {
    padding: ${spacing.xl};
  }
`;

const StyledButton = styled.button`
  border: none;
  background-color: transparent;

  &:hover {
    cursor: pointer;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DotsWrapper = styled.div`
  display: flex;
`;

const StarsWrapper = styled.div`
  width: 100%;
  text-align: center;
`;

const ReviewWrapper = styled.div`
  min-height: calc(12em + 32px);

  @media ${desktopBreakpoint} {
    min-height: 11em;
  }
`;
