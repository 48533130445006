import styled from 'styled-components';
import {
  colors,
  desktopBreakpoint,
  spacing,
} from '../../../app/components/styled';

export const LegalTextContainer = styled.div`
  margin-bottom: ${spacing.lg};
  align-self: end;
  color: ${colors.slate};
  padding: 0 ${spacing.xl};

  @media ${desktopBreakpoint} {
    padding: ${spacing.xl};
    width: 80%;
    padding-right: 0;
  }
`;
