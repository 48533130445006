import { APIProvider } from '@vis.gl/react-google-maps';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { getGooglePlacesKey } from './shared-constants';
import { WebsiteRoot } from './website/root/website-root';

const bootstrap = async () => {
  const containerElement = document.getElementById('root');
  const root = createRoot(containerElement!);

  root.render(
    <React.StrictMode>
      <APIProvider apiKey={getGooglePlacesKey()}>
        <WebsiteRoot />
      </APIProvider>
    </React.StrictMode>
  );
};

bootstrap();
