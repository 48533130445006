import { baseUrl } from '../base-url';

export const deleteUser = async (userId: string) => {
  try {
    const token = localStorage.getItem('token');
    return await fetch(`${baseUrl}/api/user`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ userId }),
    });
  } catch (err) {
    console.log('Failed to delete user.', err);
  }
};
