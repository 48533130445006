import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {
  colors,
  desktopBreakpoint,
  fontWeight,
  spacing,
} from '../../../app/components/styled';
import { iconAndNameWhite } from '../../images';
import { SectionWrapper } from '../../shared/components/section-wrapper';

export const FooterOne = () => {
  return (
    <SectionWrapper
      backgroundColor={colors.blue}
      maxWidth='1800px'
      isSticky={true}
    >
      <ContentWrapper>
        <LogoContainer>
          <Link to={'/'}>
            <Logo src={iconAndNameWhite} />
          </Link>
        </LogoContainer>
        {/* TODO: Uncomment */}
        {/* <Nav>
          <NavItem>
            <Link to='business/register'>Register your business</Link>
          </NavItem>
        </Nav> */}
      </ContentWrapper>
    </SectionWrapper>
  );
};

const ContentWrapper = styled.div`
  width: 100%;
  padding-top: ${spacing.md};
  padding-bottom: ${spacing.md};
  display: flex;
  flex-direction: row;
`;

const LogoContainer = styled.div`
  margin-left: auto;
  margin-right: auto;

  @media ${desktopBreakpoint} {
    margin-left: 0;
    margin-right: 0;
  }
`;

const Logo = styled.img`
  max-width: 120px;

  @media ${desktopBreakpoint} {
    max-width: 160px;
  }
`;

const Nav = styled.div`
  padding: 0;
  margin: 0;
  margin-left: auto;
  display: flex;
  align-items: center;
`;

const NavItem = styled.div`
  a {
    text-decoration: none;
    color: white;
    font-size: 20px;
    font-weight: ${fontWeight.medium};

    &:hover {
      text-decoration: underline;
    }
  }
`;
