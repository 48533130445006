import React, { useRef } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Feedback } from '../routes/feedback';
import { Home } from '../routes/home/home';
import { Legal } from '../routes/legal';
import { PrivacyPolicy } from '../routes/legal/privacy-policy/privacy-policy';
import { Support } from '../routes/legal/support';
import { TermsAndConditions } from '../routes/legal/terms-and-conditions';
import { HomeWrapper } from './components/home-wrapper';

export const WebsiteRoot = () => {
  const coverSectionRef = useRef<HTMLDivElement>(null);

  return (
    <BrowserRouter basename={'/'}>
      <Routes>
        <Route
          path=''
          element={<HomeWrapper coverSectionRef={coverSectionRef} />}
        >
          <Route index element={<Home ref={coverSectionRef} />} />
          <Route path='feedback' element={<Feedback />} />
          <Route path='legal' element={<Legal />} />
          <Route path='legal/privacy-policy' element={<PrivacyPolicy />} />
          <Route path='legal/support' element={<Support />} />
          <Route path='legal/terms' element={<TermsAndConditions />} />
          <Route path='*' element={<Home ref={coverSectionRef} />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
