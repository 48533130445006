import React, { forwardRef, Ref } from 'react';
import styled from 'styled-components';
import {
  colors,
  desktopBreakpoint,
  spacing,
} from '../../../../app/components/styled';
import { appPreview, appStoreButton, googlePlayButton } from '../../../images';
import { SectionWrapper } from '../../../shared/components/section-wrapper';
import { StyledParagraph } from './styled';

export const Cover = forwardRef((_, ref: Ref<HTMLDivElement>) => {
  return (
    <SectionWrapper ref={ref} backgroundColor={colors.blue}>
      <GridContainer>
        <TextContainer>
          <h1 id='cover'>
            Unlock savings and unforgettable experiences with Vibe
          </h1>
          <StyledParagraph style={{ marginTop: 0 }}>
            Find Vancouver's best happy hours, trivia nights, shows and more all
            in one place.
          </StyledParagraph>
          <ButtonsContainer>
            <a href='https://apps.apple.com/ca/app/vibe-happy-hours-and-more/id6705122593'>
              <AppStoreButton
                src={appStoreButton}
                width='150px'
                height='50px'
              />
            </a>
            <a href='https://play.google.com/store/apps/details?id=com.burnspbrian.happyhour'>
              <GooglePlayButton
                src={googlePlayButton}
                width='168px'
                height='50px'
              />
            </a>
          </ButtonsContainer>
        </TextContainer>
        <ImageContainer>
          <Image src={appPreview} width='475px' height='615px' />
        </ImageContainer>
      </GridContainer>
    </SectionWrapper>
  );
});

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  @media ${desktopBreakpoint} {
    grid-template-columns: 0.8fr 1fr;
    grid-template-rows: 1fr;
    margin-bottom: ${spacing.xl};
  }
`;

const TextContainer = styled.div`
  align-self: end;
  color: white;
  text-align: center;
  grid-column: 1;
  grid-row: 2;
  padding-bottom: ${spacing.xl};
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  @media ${desktopBreakpoint} {
    text-align: left;
    grid-column: 1;
    grid-row: 1;
    padding: ${spacing.xl};
    padding-right: 0;
    padding-bottom: 0;
  }
`;

const ImageContainer = styled.div`
  padding: 0;
  grid-column: 1;
  grid-row: 1;
  display: flex;

  @media ${desktopBreakpoint} {
    padding: 0;
    grid-column: 2;
    grid-row: 1;
  }
`;

const Image = styled.img`
  object-fit: contain;
  width: 90%;
  margin-left: auto;
  margin-right: auto;

  @media ${desktopBreakpoint} {
    width: 100%;
    max-width: 500px;
  }
`;

const ButtonsContainer = styled.div`
  margin-top: ${spacing.xl};
`;

const AppStoreButton = styled.img`
  margin-right: ${spacing.md};
  object-fit: cover;
  border-radius: 8px;
  max-height: 50px;
`;

const GooglePlayButton = styled.img`
  margin-left: ${spacing.md};
  object-fit: cover;
  border-radius: 8px;
  max-height: 50px;
`;
