import { Button } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import { colors, spacing } from '../../../../app/components/styled';
import { SectionWrapper } from '../../../shared/components/section-wrapper';

export const CtaButtonSection = () => {
  const handleClick = () => {
    window.location.hash = '';
    window.location.hash = '#cover';
  };

  return (
    <SectionWrapper backgroundColor={colors.blue}>
      <Container>
        <StyledButton onClick={handleClick}>
          Get the app now for free
        </StyledButton>
      </Container>
    </SectionWrapper>
  );
};

const Container = styled.div`
  padding-top: ${spacing.sm};
  padding-bottom: ${spacing.xl};
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${colors.blue};
  width: 100%;
`;

const StyledButton = styled(Button)`
  && {
    background-color: ${colors.slate};
    color: white;
    padding: ${spacing.lg} ${spacing.lg};
    width: fit-content;
    font-size: 1.25em;
    letter-spacing: 1;
    border-radius: 10px;
    z-index: 0;
  }
`;
