import { baseUrl } from '../base-url';

export const updatePassword = async (
  userId: string,
  oldPassword: string,
  password: string
) => {
  try {
    const token = localStorage.getItem('token');
    await fetch(`${baseUrl}/api/user/${userId}/update-password`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ oldPassword, password }),
    });
  } catch (err) {
    console.log('Failed to update user data.', err);
  }
};
