import React, { forwardRef, Ref } from 'react';
import { AppVideoPreview } from './components/app-video-preview';
import { Benefits } from './components/benefits';
import { Cover } from './components/cover';
import { CtaButtonSection } from './components/cta-button';
import { ReviewsSection } from './components/reviews-section';
import { Suggestion } from './components/suggestion';

export const Home = forwardRef((_, coverSectionRef: Ref<HTMLDivElement>) => {
  return (
    <>
      <Cover ref={coverSectionRef} />
      <AppVideoPreview />
      <Benefits />
      <CtaButtonSection />
      <ReviewsSection />
      {/* <ImageCarousel /> */}
      <Suggestion />
    </>
  );
});
