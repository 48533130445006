import React from 'react';
import { colors } from '../../../components/styled';

interface Props {
  title: string;
  paragraph: string;
}

export const PreSubmissionMessage = ({ title, paragraph }: Props) => (
  <>
    <h1 id='suggest' style={{ color: colors.blue }}>
      {title}
    </h1>
    <p style={{ color: colors.slate }}>{paragraph}</p>
  </>
);
