import React from 'react';
import { colors } from '../../../components/styled';

interface Props {
  paragraphOne?: string;
  paragraphTwo?: string;
}

export const FormSuccessMessage = ({ paragraphOne, paragraphTwo }: Props) => (
  <>
    <h1>Thank You!</h1>
    <div style={{ color: colors.slate }}>
      <p>
        {paragraphOne ??
          `Thanks for helping make Vibe even better! Your input improves the
        experience for everyone!`}
      </p>
      {paragraphTwo && <p>{paragraphTwo}</p>}
    </div>
  </>
);
