import React from 'react';
import { useHashLinks } from '../hooks/use-hash-links';

/**
 * This is a dummy empty component just to configure hash links. It needs to be added within
 * the BrowserRouter
 */
export const HashLinker = () => {
  useHashLinks();

  return <></>;
};
