import styled from 'styled-components';
import { colors, spacing } from '../../../app/components/styled';

export const FormWrapper = styled.div`
  background-color: ${colors.paleGrey};
  padding: ${spacing.lg};
  padding-top: ${spacing.md};
  padding-bottom: ${spacing.lg};
  border-radius: 10px;
  margin-top: ${spacing.xl};
  margin-bottom: ${spacing.lg};
`;

export const StyledForm = styled.form`
  width: 100%;
  color: ${colors.slate};
  text-align: center;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  padding-top: ${spacing.lg};
`;

export const StyledLabel = styled.label`
  padding-bottom: ${spacing.sm};
  font-size: 1em;
`;

export const StyledInput = styled.input`
  width: 100%;
  margin-bottom: ${spacing.lg};
  margin-top: ${spacing.sm};
  padding: ${spacing.md};
  border: none;
  background-color: white;
  border-radius: 5px;
  box-sizing: border-box;
  color: ${colors.slate};
`;

export const StyledRadioButton = styled.div`
  display: inline;
  padding-bottom: ${spacing.lg};
  padding-left: ${spacing.lg};
  padding-right: ${spacing.lg};
`;

export const StyledTextArea = styled.textarea`
  width: 100%;
  margin-bottom: ${spacing.md};
  margin-top: ${spacing.sm};
  padding: ${spacing.md};
  resize: vertical;
  border: none;
  background-color: white;
  border-radius: 5px;
  box-sizing: border-box;
  color: ${colors.slate};
`;

export const PhotoInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PhotoIconsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: ${spacing.md};
  margin-bottom: ${spacing.lg};
  align-items: center;
  margin-left: auto;
  margin-right: auto;
`;
