interface BodyData {
  name: string;
  vicinity: string;
  openingHours?: google.maps.places.PlaceOpeningHoursPeriod[];
  file: File;
  links?: string[];
}

export const pdfFileUpload = async (url: string, bodyData: BodyData) => {
  const { file, vicinity, name, links, openingHours } = bodyData;
  const formData = new FormData();
  formData.append('menu', file);
  formData.append('fileName', file.name);
  formData.append('name', name);
  formData.append('vicinity', vicinity);
  formData.append('openingHours', JSON.stringify(openingHours));
  formData.append('links', JSON.stringify(links));

  const token = localStorage.getItem('token');
  const result = await fetch(url, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: formData,
  });

  return await result.json();
};
