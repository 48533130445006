import React from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import { colors } from '../../../app/components/styled';
import { FooterOne } from './footer-one';
import { FooterTwo } from './footer-two';
import { HashLinker } from './hash-linker';
import { Header } from './header';

interface Props {
  coverSectionRef: React.RefObject<HTMLDivElement>;
}

export const HomeWrapper = ({ coverSectionRef }: Props) => {
  return (
    <Wrapper>
      <HashLinker />
      <Header coverSectionRef={coverSectionRef} />
      <MainContent>
        <Outlet />
        <div>
          <FooterOne />
          <FooterTwo />
        </div>
      </MainContent>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  font-family: 'Montserrat';

  h1 {
    line-height: 1.25;
    font-size: 2.5em;
  }

  h2 {
    line-height: 1.25;
  }

  h3 {
    line-height: 1.25;
  }

  h4 {
    font-size: 1.2em;
    font-weight: 500;
  }

  p {
    line-height: 1.5;
    font-size: 1.25em;
  }

  input {
    font-size: 1em;
    font-family: 'Montserrat';
  }

  textarea {
    font-size: 1em;
    font-family: 'Montserrat';
  }
`;

const MainContent = styled.div`
  display: flex;
  min-height: calc(100vh - 69px);
  flex-direction: column;
  justify-content: space-between;
  background-color: ${colors.paleGrey};
`;
