import { Button } from '@mui/material';
import React, { useRef } from 'react';
import styled from 'styled-components';
import { PhotoIconsWrapper, PhotoInputWrapper } from './styled-form';

interface Props {
  imgFile: File | undefined | null;
  setImgFile: (file: File | undefined | null) => void;
  Icon: React.ReactNode;
  message: string;
}

export const ImageUploadInput = ({
  imgFile,
  setImgFile,
  Icon,
  message,
}: Props) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    setImgFile(e.target.files?.[0]);
  };

  return (
    <PhotoInputWrapper>
      <PhotoIconsWrapper>
        {imgFile ? (
          <ImagePreview src={URL.createObjectURL(imgFile)} alt='img' />
        ) : (
          <Button onClick={() => fileInputRef.current?.click()}>
            <ButtonWrapper>
              {Icon}
              <span>{message}</span>
            </ButtonWrapper>
            <input
              onChange={handleImageUpload}
              multiple={false}
              ref={fileInputRef}
              type='file'
              hidden
            />
          </Button>
        )}
      </PhotoIconsWrapper>
    </PhotoInputWrapper>
  );
};

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
`;

const ImagePreview = styled.img`
  max-width: 100px;
`;
