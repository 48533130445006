import { baseUrl } from '../../app/api/base-url';

interface Props {
  subject: string;
  file?: File | null;
  turnstileToken?: string | null;
  content: string | null;
}

export const sendEmail = async ({
  subject,
  file,
  turnstileToken,
  content,
}: Props) => {
  try {
    const data = new FormData();
    data.append('subject', subject);

    if (content) {
      data.append('content', content);
    }
    if (file) {
      data.append('menu', file);
    }
    if (turnstileToken) {
      data.append('turnstileToken', turnstileToken);
    }

    const response = await fetch(`${baseUrl}/api/email`, {
      method: 'POST',
      body: data,
    });

    return response.status === 200;
  } catch (err) {
    console.log('Failed to send email', err);
  }
};
