import React from 'react';
import styled from 'styled-components';
import {
  colors,
  desktopBreakpoint,
  spacing,
} from '../../../../app/components/styled';
import { SectionWrapper } from '../../../shared/components/section-wrapper';
import { StyledParagraph } from './styled';

const youtubeID = 'kP6djD8fCe4';
const playlistID = 'PLTH5AiWZ0g_c__g6FGYAAYmCkoqC_mfz4';

export const AppVideoPreview = () => {
  return (
    <SectionWrapper backgroundColor={colors.paleGrey}>
      <GridContainer>
        <TextContainer>
          <h1>See Vibe in action</h1>
          <StyledParagraph style={{ color: colors.slate }}>
            Discover how Vibe makes finding the best happy hours and local
            events a breeze.
          </StyledParagraph>
          <StyledList>
            <StyledListItem>
              Filter by what's on now or at a specific time.
            </StyledListItem>
            <StyledListItem>Browse the list.</StyledListItem>
            <StyledListItem>Save your favourites.</StyledListItem>
          </StyledList>
        </TextContainer>
        <VideoContainer>
          <StyledIframe
            className='video'
            title='Youtube player'
            sandbox='allow-same-origin allow-forms allow-popups allow-scripts allow-presentation'
            src={`https://youtube.com/embed/${youtubeID}?autoplay=1&mute=1&list=${playlistID}&loop=1&rel=0`}
            width='300'
            height='600'
            loading='lazy'
          />
        </VideoContainer>
      </GridContainer>
    </SectionWrapper>
  );
};

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  padding-top: ${spacing.lg};
  padding-bottom: ${spacing.xl};

  @media ${desktopBreakpoint} {
    grid-template-columns: 0.8fr 1fr;
    grid-template-rows: 1fr;
  }
`;

const TextContainer = styled.div`
  color: white;
  text-align: center;
  grid-column: 1;
  grid-row: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: ${colors.blue};

  @media ${desktopBreakpoint} {
    text-align: left;
    grid-column: 2;
    grid-row: 1;
    padding: ${spacing.xl};
    padding-right: 0;
  }
`;

const VideoContainer = styled.div`
  padding: ${spacing.lg};
  padding-bottom: 0;
  grid-column: 1;
  grid-row: 2;
  display: flex;
  justify-content: center;

  @media ${desktopBreakpoint} {
    padding: ${spacing.lg};
    grid-column: 1;
    grid-row: 1;
  }
`;

const StyledIframe = styled.iframe`
  border-radius: 20px;
  border-width: 0;

  border-top-width: 15px;
  border-bottom-width: 15px;
  border-color: black;
`;

const StyledList = styled.ul`
  display: none;

  @media ${desktopBreakpoint} {
    display: block;
  }
`;

const StyledListItem = styled.li`
  color: ${colors.slate};
  margin-top: ${spacing.sm};
  margin-bottom: 0;
  font-size: 1.25em;
`;
