import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import { Button } from '@mui/material';
import { useMapsLibrary } from '@vis.gl/react-google-maps';
import React, { useEffect, useRef, useState } from 'react';
import Turnstile from 'react-turnstile';
import styled from 'styled-components';
import { sendEmail } from '../../../../app/api';
import { ImageUploadInput } from '../../../../app/features/shared/components/image-input';
import { turnstileSiteKey } from '../../../../shared-constants';
import {
  FormWrapper,
  StyledForm,
  StyledInput,
  StyledRadioButton,
  StyledTextArea,
} from '../../../shared/components/styled-form';
import { PlaceType } from '../../../types';

interface Props {
  setSubmitted: (submitted: boolean) => void;
}

export const SuggestionForm = ({ setSubmitted }: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const placesLib = useMapsLibrary('places');
  const [autocomplete, setAutocomplete] = useState<PlaceType | null>(null);
  const [place, setPlace] = useState<google.maps.places.PlaceResult | null>(
    null
  );
  const [link, setLink] = useState<string | null>(null);
  const [comment, setComment] = useState<string | null>(null);
  const [email, setEmail] = useState<string | null>(null);
  const [owner, setOwner] = useState(false);
  const [imgFile, setImgFile] = useState<File | undefined | null>(null);
  const isMobileDevice = /Mobi/i.test(window.navigator.userAgent);
  const [turnstileToken, setTurnstileToken] = useState<string | null>(null);
  const [error, setError] = useState('');

  useEffect(() => {
    if (!placesLib || !inputRef.current) return;

    const options: google.maps.places.AutocompleteOptions = {
      types: ['establishment'],
      componentRestrictions: { country: 'can' },
      fields: ['name'],
    };

    setAutocomplete(new placesLib.Autocomplete(inputRef.current, options));
  }, [placesLib]);

  useEffect(() => {
    if (!autocomplete) return;

    autocomplete.addListener('place_changed', () => {
      setPlace(autocomplete.getPlace());
    });
  }, [autocomplete]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const submit = async () => {
      if (place && place.name) {
        const content = `
Name: ${place.name}.

Submitter email: ${email}
Business owner: ${owner ? 'yes' : 'no'}.
  
${link !== undefined ? `Menu URL: ${link}` : ''}

${comment !== undefined ? `Comments: ${comment}` : ''}
  
          `;

        const successful = await sendEmail({
          subject: `Pub Suggestion - ${place.name}`,
          content,
          file: imgFile,
          turnstileToken,
        });
        if (successful) {
          setSubmitted(true);
        } else {
          setError('There was an error submitting the suggestion.');
        }
      }
    };

    submit();
  };

  return (
    <FormWrapper>
      <StyledForm onSubmit={handleSubmit}>
        <StyledInput
          id='business-name-input'
          ref={inputRef}
          placeholder='Business name*'
          required
        />
        <StyledInput
          id='email-input'
          placeholder='Your email address'
          type='email'
          onChange={(e) => setEmail(e.target.value)}
        />
        <p>Do you work for this business?</p>
        <StyledRadioButton>
          <input
            id='owner-yes'
            type='radio'
            value='Yes'
            checked={owner}
            onChange={() => setOwner(true)}
          />
          <label htmlFor='owner-yes'>Yes</label>
        </StyledRadioButton>
        <StyledRadioButton>
          <input
            id='owner-no'
            type='radio'
            value='No'
            checked={!owner}
            onChange={() => setOwner(false)}
          />
          <label htmlFor='owner-no'>No</label>
        </StyledRadioButton>
        <br />
        <p></p>

        {!isMobileDevice && (
          <>
            <StyledInput
              id='menu-link-input'
              placeholder='Menu link'
              onChange={(e) => setLink(e.target.value)}
            />
          </>
        )}
        <StyledTextArea
          id='comments-input'
          onChange={(e) => setComment(e.target.value)}
          placeholder='Comments'
        />
        {isMobileDevice && (
          <ImageUploadInput
            imgFile={imgFile}
            setImgFile={setImgFile}
            Icon={<AddAPhotoIcon />}
            message='Capture Menu'
          />
        )}
        <Turnstile
          sitekey={turnstileSiteKey}
          onVerify={(token) => setTurnstileToken(token)}
        />
        <StyledButton type='submit' disabled={!place}>
          Submit
        </StyledButton>
        {error && <span style={{ color: 'red' }}>{error}</span>}
      </StyledForm>
    </FormWrapper>
  );
};

const StyledButton = styled(Button)`
  && {
    font-size: 1.2em;
  }
`;
